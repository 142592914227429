@font-face {
  font-family: Almoni-Ragular;
  src: url('../assets/fonts//almoni-tzar-regular-aaa.ttf');
}

@font-face {
  font-family: Almoni-Bold;
  src: url('../assets/fonts/almoni-tzar-bold-aaa.ttf');
}

@font-face {
  font-family: Almoni-Ragular-dl;
  src: url('../assets/fonts/almoni-dl-aaa-regular.ttf');
}

@font-face {
  font-family: Almoni-Bold-dl;
  src: url('../assets/fonts/almoni-dl-aaa-bold.ttf');
}

html {
  height: 100%;
}

html>* {
  font-family: Almoni-Ragular;
}

body {
  overflow: auto;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

:root {
  --primary-orange: #FD9C16;
  --secondery-orange: #fab24d;
  --background-dark: #171717;
  --background-light: #FFF;
  --background-disable: #e5e5e5;
  --neutral-50p: #000000;
  --neutral-40p: #8F8F8F;
  --neutral-30p: #E0E0E0;
  --neutral-20p: #F2F2F4;
  --neutral-10p: #FEFEFE;
  --neutral-50p-dark: #EFE2DA;
  --red: #D32F2F;
  --red-shade: #ef5350;
  --orange: #ed6c02;
  --orange-shade: #ff9800;
  --blue: #0288d1;
  --blue-shade: #03a9f4;
  --green: #2e7d32;
  --green-shade: #4caf50;
}


/* accounts table modification */
.fui-TableCellLayout__content.___1ccp5kb_14c66jm.f22iagw.f1vx9l62 {
  width: 100%;
}

/* disabled switch */
.rw4brat:disabled {
  cursor: not-allowed;
}